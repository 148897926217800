import ConfirmModal from "components/Common/ConfirmModal"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useEffect, useState } from "react"

import { Badge, Button, Card, CardBody, Col, Label, Row, Spinner, UncontrolledTooltip } from "reactstrap"
import moment from "moment"
import { Controller, useForm } from "react-hook-form"
import Employees from "../../api/employees"
import Missions from "../../api/missions"
import Products from "../../api/products"
import ExaminationsApi from "../../api/examinations"
import CustomPagination from "../../components/CustomPagination"
import Select from "react-select"
import Customers from "../../api/customers"
import * as XLSX from "xlsx" //for excell export
import { saveAs } from "file-saver"
import FlatPicker from "react-flatpickr"
import Form from "../../helpers/form" //for excell export

const Examinations = () => {
	document.title = "Xidmətlər və Məhsullar"
	const [confirmModal, setConfirmModal] = useState(false)
	const [examinationsIsFetching, setExaminationsIsFetching] = useState(false)
	const [examinations, setExaminations] = useState([])
	const [page, setPage] = useState(1)
	const [total, setTotal] = useState(0)
	const [employees, setEmployees] = useState([])
	const [doctors, setDoctors] = useState([])
	const [missions, setMissions] = useState([])
	const [products, setProducts] = useState([])
	const [patients, setPatients] = useState([])
	const [limit, setLimit] = useState(500)
	const { control, reset, watch, formState: { errors } } = useForm()

	const getColor = item => item?.debt > 0 ? { background: "#f46a6a", color: "#fff" } : {
		background: "#34c38f",
		color: "#fff"
	}

	const deleteData = async () => {
		await ExaminationsApi.delete(confirmModal)
		await filterExaminations(page)
	}

	const fetchEmployees = async () => {
		const { data } = await Employees.getSelect()
		setEmployees(data)
	}

	const fetchMissions = async () => {
		const { data } = await Missions.getSelect()
		setMissions(data)
	}

	const fetchProducts = async () => {
		const { data } = await Products.getSelect()
		setProducts(data)
	}

	const fetchPatients = async () => {
		const { data } = await Customers.getSelect()
		setPatients(data)
	}

	const fetchDoctors = async () => {
		const { data } = await Employees.getDoctors()
		setDoctors(data)
	}

	const fetchFilterData = async () => {
		await Promise.all([fetchEmployees(), fetchMissions(), fetchProducts(), fetchPatients(), fetchDoctors()])
	}

	const doctorId = watch("doctorId")
	const senderDoctorId = watch("senderDoctorId")
	const missionId = watch("missionId")
	const productId = watch("productId")
	const patientId = watch("patientId")
	const start_date = watch("start_date")
	const end_date = watch("end_date")

	const filterExaminations = async (p = null) => {
		setExaminationsIsFetching(true)
		const prepareData = {
			patient_id: patientId?.value || null,
			doctor_id: doctorId?.value || null,
			worker_id: senderDoctorId?.value || null,
			start_date: start_date ? Form.convertFormDate(new Date(start_date)) : null,
			end_date: end_date ? Form.convertFormDate(new Date(end_date)) : null,
			limit: limit,
			page: p || page
		}

		if (missionId && productId) {
			prepareData.service_id = [missionId?.value2, productId.value]
			delete prepareData.is_product
		} else if (missionId) {
			prepareData.service_id = [missionId?.value2]
			prepareData.is_product = missionId?.is_product
		} else if (productId) {
			prepareData.service_id = [productId?.value]
			prepareData.is_product = 1
		} else {
			prepareData.service_id = null
			delete prepareData.is_product
		}
		const data = await ExaminationsApi.get(prepareData)

		setExaminations(data?.data)
		setTotal(data?.meta?.total)
		setExaminationsIsFetching(false)
	}

	useEffect(() => {
		fetchFilterData()
	}, [])

	useEffect(() => {
		filterExaminations(page)
		setPage(1)
	}, [patientId, doctorId, senderDoctorId, missionId, productId, start_date, end_date])

	useEffect(() => {
		filterExaminations(page)
	}, [page])

	const exportToExcel = () => {
		const customHeaders = {
			"id": "№",
			"patient": "Müştəri",
			"doctor": "Nümayəndə",
			"worker": "Göndərən şəxs",
			"service": "Xidmət",
			"quantity": "Say",
			"price": "Qiymət",
			"discount": "Endirim faizi",
			"final_price": "Yekun qiymət",
			"summ": "Cəm",
			"payment": "Ödəniş",
			"debt": "Borc",
			"admission_date": "Qəbul tarixi",
			"expiration_date": "Bitmə tarixi",
			"payment_date": "Ödəniş tarixi",
			"note": "Qeyd"
		}

		const transformedData = examinations?.examinations?.map(row => {
			let transformedRow = {}
			for (let key in customHeaders) {
				if (key === "summ") {
					// Calculate summ as the product of quantity and final_price
					transformedRow[customHeaders[key]] = row["quantity"] * row["final_price"]
				} else {
					transformedRow[customHeaders[key]] = row[key]
				}
			}
			return transformedRow
		})

		const worksheet = XLSX.utils.json_to_sheet(transformedData)

		const workbook = XLSX.utils.book_new()

		XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")

		// Buffer to store the generated Excel file
		const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
		const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })

		saveAs(blob, "xidmetler_ve_mehsullar_detalli_export.xlsx")
	}

	return (
		<div className="page-content">
			<ConfirmModal active={confirmModal} setActive={setConfirmModal} callback={deleteData} />
			<div className="container-fluid">
				<Breadcrumbs breadcrumbItem={`XİDMƏTLƏR VƏ MƏHSULLAR (${total})`} />
				<Row>
					<Col sm={12}>
						<div className="d-flex flex-column gap-2">
							<Card>
								<CardBody>
									<Row>
										<Col sm={12} md={3}>
											<div className="mb-3">
												<Label for="patientId">Müştəri</Label>
												<Controller name="patientId"
															control={control}
															render={({ field: { value, onChange } }) => (
																<Select
																	isClearable
																	options={patients}
																	placeholder=""
																	className={`w-100 ${errors?.patientId && "is-invalid"}`}
																	onChange={onChange}
																	value={value}
																	name="patientId"
																	id="patientId" />
															)} />
											</div>
										</Col>
										<Col sm={12} md={3}>
											<div className="mb-3">
												<Label for="doctorId">Nümayəndə</Label>
												<Controller name="doctorId"
															control={control}
															render={({ field: { value, onChange } }) => (
																<Select
																	isClearable
																	options={doctors}
																	placeholder=""
																	className={`w-100 ${errors?.doctorId && "is-invalid"}`}
																	onChange={onChange}
																	value={value}
																	name="doctorId"
																	id="doctorId" />
															)} />
											</div>
										</Col>
										<Col sm={12} md={3}>
											<div className="mb-3">
												<Label for="senderDoctor">Göndərən şəxs</Label>
												<Controller name="senderDoctorId"
															control={control}
															render={({ field: { value, onChange } }) => (
																<Select
																	isClearable
																	options={employees}
																	placeholder=""
																	className={`w-100 ${errors?.senderDoctorId && "is-invalid"}`}
																	onChange={onChange}
																	value={value}
																	name="senderDoctorId"
																	id="senderDoctorId" />
															)} />
											</div>
										</Col>
										<Col sm={12} md={3}>
											<div className="mb-3">
												<Label for="missionId">Xidmət</Label>
												<Controller name="missionId"
															control={control}
															render={({ field: { value, onChange } }) => (
																<Select
																	isClearable
																	options={missions}
																	placeholder=""
																	className={`w-100 ${errors?.missionId && "is-invalid"}`}
																	onChange={onChange}
																	value={value}
																	name="missionId"
																	id="missionId" />
															)} />
											</div>
										</Col>
										<Col sm={12} md={3}>
											<div className="mb-3">
												<Label for="missionId">Məhsul</Label>
												<Controller name="productId"
															control={control}
															render={({ field: { value, onChange } }) => (
																<Select
																	isClearable
																	options={products}
																	placeholder=""
																	className={`w-100 ${errors?.productId && "is-invalid"}`}
																	onChange={onChange}
																	value={value}
																	name="productId"
																	id="productId" />
															)} />
											</div>
										</Col>
										<Col sm={12} md={3}>
											<div className="mb-3 w-100">
												<Label for="start_date">Başlama tarixi</Label>
												<Controller name="start_date"
															control={control}
															render={({ field: { value, onChange } }) => (
																<FlatPicker
																	className="form-control d-block"
																	value={value}
																	onChange={onChange}
																	options={{
																		locale: "az"
																	}}
																/>
															)} />
											</div>
										</Col>
										<Col sm={12} md={3}>
											<div className="mb-3 w-100">
												<Label for="end_date">Bitmə tarixi</Label>
												<Controller name="end_date"
															control={control}
															render={({ field: { value, onChange } }) => (
																<FlatPicker
																	className="form-control d-block"
																	value={value}
																	onChange={onChange}
																	options={{
																		locale: "az"
																	}}
																/>
															)} />
											</div>
										</Col>

										<Col sm={12}>
											<div className="d-flex justify-content-end">
												<Button id="reset-btn" outline color="primary" onClick={() => {
													reset({
														doctorId: null,
														patientId: null,
														senderDoctorId: null,
														missionId: null
													})
													setPage(1)
													filterExaminations(1)
												}}>
													<i className="bx bx-rotate-right" />
												</Button>
												<UncontrolledTooltip placement="bottom" target="reset-btn">
													Sıfırla
												</UncontrolledTooltip>
												<Button
													onClick={exportToExcel}
													type="button"
													color="success"
													className="btn-label ms-3"
												>
													<i className="bx bx-download label-icon" /> Export
												</Button>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
							<Card>
								{examinationsIsFetching ? (
									<div className="d-flex justify-content-center p-5">
										<Spinner color="primary" size="lg" />
									</div>
								) : (
									<CardBody>
										<div className="table-responsive">
											<table className="table table-bordered">
												<thead>
												<tr>
													<th>№</th>
													<th>Müştəri</th>
													<th>Nümayəndə</th>
													<th>Göndərən şəxs</th>
													<th>Xidmət</th>
													<th>Say</th>
													<th>Qiymət</th>
													<th>Endirim faizi</th>
													<th>Yekun qiymət</th>
													<th>Cəm</th>
													<th>Ödəniş</th>
													<th>Borc</th>
													<th>Qəbul tarixi</th>
													<th>Bitmə tarixi</th>
													<th>Ödəniş tarixi</th>
													<th>Qeyd</th>
													<th />
												</tr>
												</thead>
												<tbody>
												{examinations?.examinations?.map((item, index) => (
													<tr key={item.id}>
														<td style={getColor(item)}>{index + 1}</td>
														<td style={getColor(item)}>{item?.patient}</td>
														<td style={getColor(item)}>{item?.doctor}</td>
														<td style={getColor(item)}>{item?.worker}</td>
														<td style={getColor(item)}>
															<Badge
																color={(item?.is_product === 1) ? "primary" : "success"}>
																{(item?.is_product === 1) ? "Məhsul" : "Xidmət"}
															</Badge>&nbsp;
															{item?.service}
														</td>
														<td style={getColor(item)}>{item?.quantity}</td>
														<td style={getColor(item)}>{item?.price}</td>
														<td style={getColor(item)}>{item?.discount}</td>
														<td style={getColor(item)}>{item?.final_price}</td>
														<td style={getColor(item)}>{item?.quantity * item?.final_price}</td>
														<td style={getColor(item)}>{item?.payment}</td>
														<td style={getColor(item)}>{item?.debt}</td>
														<td
															style={getColor(item)}>{item?.admission_date && moment(item?.admission_date).format("DD.MM.YYYY")}</td>
														<td
															style={getColor(item)}>{item?.expiration_date && moment(item?.expiration_date).format("DD.MM.YYYY")}</td>
														<td
															style={getColor(item)}>{item?.payment_date && moment(item?.payment_date).format("DD.MM.YYYY")}</td>
														<td style={getColor(item)}>{item?.note}</td>
														<td>
															<Button color="danger"
																	id={`delete-${item.id}`}
																	onClick={() => setConfirmModal(item.id)}>
																<i className="bx bx-trash" />
															</Button>
															<UncontrolledTooltip target={`delete-${item.id}`}
																				 placement="bottom">
																Sil
															</UncontrolledTooltip>
														</td>
													</tr>
												))}
												<tr>
													<td />
													<td />
													<td />
													<td />
													<td />
													<td />
													<td>
														<Badge className="p-2 font-size-12"
															   color="warning">{examinations?.total_price}</Badge>
													</td>
													<td />
													<td>
														<Badge className="p-2 font-size-12"
															   color="info">{examinations?.total_final_price}</Badge>
													</td>
													<td>
														<Badge className="p-2 font-size-12"
															   color="dark">{examinations?.total_sum}</Badge>
													</td>
													<td>
														<Badge className="p-2 font-size-12"
															   color="success">{examinations?.total_payment}</Badge>
													</td>
													<td>
														<Badge className="p-2 font-size-12"
															   color="danger">{examinations?.total_debt}</Badge>
													</td>
													<td />
													<td />
													<td />
												</tr>
												</tbody>
											</table>
										</div>
										<CustomPagination total={total} limit={limit} setPage={setPage} page={page} />
									</CardBody>
								)}
							</Card>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default Examinations